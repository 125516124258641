<template>
  <div class="home">
    <div class="mb-4">
      <div class="d-none d-lg-block">
        <the-navbar />
      </div>
    </div>
  </div>
  <slot />
</template>

<script>
import TheNavbar from "@/components/dashboard/TheNavbar.vue"

export default {
  name: "DashboardLayout",
  components: {
    TheNavbar
  }
}
</script>
