<template>
  <!-- admin post dashboard table component to view all users posts-->
  <div class="table-responsive">
    <table class="table text-start align-middle table-borderless table-hover">
      <thead>
      <tr>
        <th class="th">
          <input
            type="checkbox"
            class="form-check-input me-3 fs-5"
          />
          CREATED BY
        </th>
        <th class="th">POST</th>
        <th class="th">LIKES</th>
        <th class="th">VIEWS</th>
        <th class="th">DATE</th>
        <th class="th">ACTIONS</th>
      </tr>
      </thead>
      <tbody
        v-for="post in admin_posts"
        :key="post.id"
      >
      <tr class="">
        <td>
          <input
            type="checkbox"
            class="form-check-input me-3 fs-5 align-top"
          />
          {{ post.user.first_name }} {{ post.user.last_name }}
          <i
            class="bi bi-patch-check-fill text-success ms-1"
            v-if="post.user.email_verified_at != null"
          ></i>
        </td>
        <td>{{ post.description }}</td>
        <td>{{ post.likes.length }}</td>
        <td>{{ post.view_count  }}</td>
        <td>{{ post.created_at }}</td>
        <td>
          <div class="col-md-2">
            <div class="d-flex">
              <button
                class="btn"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#viewPost' + post.id"
              >
                <i class="bi bi-eye-fill fs-5"></i>
              </button>
              <button
                class="btn"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#postDeleteModal' + post.id"
              >
                <i class="bi bi-trash-fill fs-5"></i>
              </button>
            </div>
          </div>
          <post-detail-modal :post="post" :user="user"></post-detail-modal>
          <post-delete-modal :post_id="post.id" section="admin"></post-delete-modal>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import PostDetailModal from "@/components/dashboard/PostDetailModal.vue";
import PostDeleteModal from "@/components/post/PostDeleteModal.vue";

export default {
  name: "PostTable",
  components: {PostDeleteModal, PostDetailModal},
  props: {
    admin_posts: Array
  }
}
</script>

<style scoped>
.table-hover tbody tr:hover td {
  background: #FFE3F7;
}
.bi-trash-fill {
  color: #7d90b2;
}
.bi-trash-fill:hover {
  color: #4f4f4f;
}
.bi-eye-fill {
  color: #7d90b2;
}
.bi-eye-fill:hover {
  color: #4f4f4f;
}
.th {
  background-color: #F4F7FC;
  color: #2E3B52;
}
</style>