<template>
  <div
    class="modal fade"
    :id="'viewPost' + post.id"
    tabindex="-1"
    aria-labelledby="viewPost"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="p-3">
          <div class="d-flex justify-contents-between align-items-center">
            <button type="button" class="btn" data-bs-dismiss="modal">
              <i class="bi bi-arrow-left"></i>
            </button>
            <button
              class="btn btn-primary ms-auto"
              type="button"
              data-bs-toggle="modal"
              :data-bs-target="'#postDeleteModal' + post.id"
            >
              <span class="text-white">Delete</span>
              <span class="ms-1"
                ><i class="bi bi-trash-fill text-white" style="color: #7d90b2">
                </i
              ></span>
            </button>
          </div>
        </div>
        <div class="modal-body p-3">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <profile-image
                :user="post.user"
                :size="40"
                class="me-3"
              ></profile-image>
              <div class="d-flex flex-column ps-3 mt-2">
                <div class="poster-name align-self-start">
                  {{ post.user.first_name }}
                  {{ post.user.last_name }}
                  <i
                    class="bi bi-patch-check-fill text-success ms-1"
                    v-if="post.user.email_verified_at != null"
                  ></i>
                </div>
                <div class="post-time">
                  <span class="me-3">
                    <i class="bi bi-eye-fill"></i> {{ post.view_count }} views
                  </span>
                  <span>
                    {{
                      moment(post.created_at).format("MMMM Do YYYY, h:mm:ss a")
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p
            class="my-2 text-start post_description px-3"
            style="white-space: pre-line; overflow-wrap: break-word"
          >
            {{ post.description }}
          </p>
          <AdvancedImage
            :cldImg="postImg"
            :plugins="imgPlugins"
            v-if="post.image_path"
            class="rounded-3 mt-2"
          />
          <video
            :id="'post-player' + post.id"
            controls
            muted
            class="cld-video-player cld-video-player-skin-dark cld-fluid my-4 px-2"
            v-if="post.video_path"
          ></video>
          <div
            class="d-flex align-items-center justify-content-between py-2 px-3"
            style="border-bottom: 1px solid #e8eaed"
          >
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center py-2 me-2">
                <button
                  class="like-btn d-flex align-items-center justify-content-center"
                  @click="toggleLike(post.id)"
                >
                  <font-awesome-icon
                    v-if="hasLiked"
                    :icon="['fas', 'thumbs-up']"
                    class="icon"
                    :style="{ color: 'blue' }"
                  />
                  <font-awesome-icon
                    v-else
                    :icon="['far', 'thumbs-up']"
                    class="icon"
                    :style="{ color: 'blue' }"
                  />
                </button>
                <span class="like-text">{{ post.likes.length }}</span>
              </div>

              <div class="d-flex align-items-center py-2 ms-2">
                <button
                  class="like-btn d-flex align-items-center justify-content-center"
                  @click="showComments"
                >
                  <i class="bi bi-chat-dots me-2" style="color: #a5a5a5"></i>
                  <span class="like-text"
                    >{{ post.comments.length }} Comments</span
                  >
                </button>
              </div>
            </div>
            <div class="ms-auto">
              <div class="d-flex align-items-center py-2 me-2">
                <button
                  class="like-btn d-flex align-items-center justify-content-center"
                >
                  <i class="bi bi-share" style="color: #a5a5a5"></i>
                </button>
                <span class="like-text ms-2">share</span>
              </div>
            </div>
          </div>
          <admin-comment-section
            v-if="isComments"
            :comments="post.comments"
            :post_id="post.id"
          >
          </admin-comment-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import { mapActions } from "vuex"
import { getCloudinaryPId } from "@/utils/misc"
import AdminCommentSection from "@/components/dashboard/AdminCommentSection.vue"
import ProfileImage from "@/components/profile/ProfileImage.vue"

import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder
} from "@cloudinary/vue"

export default {
  name: "PostDetailModal",
  props: { post: Object, user: Object },
  components: { AdminCommentSection, ProfileImage, AdvancedImage },
  data() {
    const postImg = this.cld.image(getCloudinaryPId(this.post.image_path))

    const imgPlugins = [
      lazyload(),
      responsive(),
      placeholder({ mode: "pixelate" })
    ]
    return {
      isComments: false,
      postImg,
      imgPlugins
    }
  },
  created() {
    this.moment = moment
  },
  mounted() {
    // only initialize player if post is a video post
    if (this.post.video_path) {
      this.initPlayer()
    }
  },
  computed: {
    hasLiked() {
      return this.post.likes?.some((x) => x.user_id == this.user?.id)
    }
  },
  methods: {
    ...mapActions("like", ["togglePostLike"]),
    toggleLike(post_id) {
      this.togglePostLike({ id: post_id, user_id: this.user.id })
    },
    getCloudinaryPId: getCloudinaryPId,
    showComments() {
      this.isComments = !this.isComments
    },
    initPlayer() {
      const logUrl =
        "https://res.cloudinary.com/dkj13ry1j/image/upload/v1688931206/Propelond_Logo2_qwybsb.png"
      const vPlayer = window.cloudinary.videoPlayer(
        `post-player${this.post.id}`,
        {
          cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
          secure: true,
          logoImageUrl: logUrl,
          logoOnclickUrl: "https://www.propelond.com/",
          playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 1.25, 1.75, 2],
          colors: { accent: "#FF0DB8" }
        }
      )
      vPlayer.source(getCloudinaryPId(this.post.video_path))
      vPlayer.transformation({ quality: "auto" })
    }
  }
}
</script>

<style scoped>
.post-image {
  width: 38rem;
  max-height: 80vh;
}
.like-btn {
  outline: none;
  border: none;
  background-color: #fff;
}
.comment-box {
  background-color: #f6f9fc;
  min-height: 62px;
  border-radius: 15px;
}
.comment-input {
  background-color: #f6f9fc;
  /* border: none;
  outline: none; */
  width: 12rem;
}
</style>
