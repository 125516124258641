<template>
  <div
    class="d-flex align-items-center px-2 mt-3"
    v-for="comment in comments"
    :key="comment.id"
  >
    <div class="ms-1">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <span class="commenter-name">
            {{ comment.user.first_name }} {{ comment.user.last_name }}
          </span>
          <i
            class="bi bi-patch-check-fill text-success ms-1"
            v-if="comment.user.email_verified_at != null"
          ></i>
          <span class="comment-time ms-2">
            {{ moment(comment.created_at).fromNow() }}</span
          >
        </div>
      </div>
      <div class="d-flex">
        <div class="py-1 rounded-3 d-flex">
          <span
            class="comment-text guest-comment-box p-2 mt-2"
            style="white-space: pre-line"
            :id="'commentDetails' + comment.id"
            :ref="'commentDetails' + comment.id"
          >
            {{ comment.content }}
          </span>
        </div>
        <button
          class="btn"
          type="button"
          @click="removeComment(comment.id, comment.post_id)"
        >
          <i class="bi bi-trash-fill" style="color: #7d90b2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import moment from "moment"

export default {
  name: "AdminCommentSection",
  props: {
    comments: Array,
    post_id: Number,
    showOption: Function
  },
  data() {
    return {
      deletedItem: {
        comment_id: null,
        post_id: null
      }
    }
  },
  created() {
    this.moment = moment
  },
  methods: {
    ...mapActions("comment", ["deleteComment"]),
    removeComment(commentId, postId) {
      this.deletedItem = Object.assign(
        {},
        {
          comment_id: commentId,
          post_id: postId
        }
      )
      this.deleteComment({ ...this.deletedItem, section: "admin" })
      this.resetComment()
    },
    resetComment() {
      this.deletedItem = Object.assign({}, { comment_id: null, post_id: null })
    }
  }
}
</script>

<style>
.comment-text {
  text-align: justify;
  font-size: 0.8rem;
  background-color: #f6f9fc;
}
.guest-comment-box {
  width: 35rem;
  background: #f6f9fc;
  border-radius: 8px;
  min-height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #6d6d6d;
}
.commenter-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #000000;
}
.comment-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #c4c4c4;
}
</style>
