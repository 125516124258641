<template>
  <div>
    <nav
      class="navbar navbar-expand-md navbar-light bg-white shadow-sm py-2 fixed-top"
    >
      <div class="container">
        <router-link to="/" class="me-4 bg-white text-decoration-none">
          <!-- <img src="../assets/logo.png" alt="logo" class="logo"> -->
          <h1 class="navbar-brand" style="color: #ff0bb9">Propelond</h1>
        </router-link>
        <!-- <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="{{ __('Toggle navigation') }}"
        >
          <span class="navbar-toggler-icon"></span>
        </button> -->

        <div
          class="collapse navbar-collapse"
          id="navbarSupportedContent"
          v-if="isAuthenticated"
        >
          <!-- middle Of Navbar -->
          <ul class="navbar-nav me-auto ms-2">
            <div class="d-flex align-items-center search-bar px-4">
              <svg
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
              <input
                type="text"
                class="search-box py-1 px-2"
                placeholder="Start typing to search.."
              />
            </div>

            <div class="ms-5 d-flex align-items-center">
              <router-link
                to="/dashboard/posts"
                class="nav-link-icons mx-2 px-3 py-2"
              >
                <i class="bi bi-clipboard-plus fs-5"></i>
              </router-link>
              <router-link
                to="/dashboard/users"
                class="nav-link-icons mx-2 px-3 py-2"
              >
                <i class="bi bi-person-fill fs-5"></i>
              </router-link>
              <router-link
                to="/dashboard/playlist"
                class="nav-link-icons mx-2 px-3 py-2"
              >
                <i class="bi bi-file-music fs-5"></i>
              </router-link>
              <!-- <router-link to="/profile" class="nav-link-icons mx-2 px-2 py-2">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4a4 4 0 0 1 4 4 4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4Z"/></svg>
              </router-link> -->
            </div>
          </ul>

          <!-- Right Side Of Navbar -->

          <ul class="navbar-nav ms-auto" v-if="isAuthenticated">
            <div class="d-flex align-items-center px-4">
              <button class="btn rounded-3 mx-1">
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-bell text-primary"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                  />
                </svg>
              </button>

              <button class="btn rounded-3 mx-1">
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-gear text-primary"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"
                  />
                  <path
                    d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"
                  />
                </svg>
              </button>
              <!-- <img src="../assets/img/profile.jpg" alt="" class="profile-img img-fluid ms-2"> -->
              <div class="dropdown" v-if="isAuthenticated">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    v-if="user"
                    :src="
                    user.avatar
                      ? user.avatar
                      : 'http://www.gravatar.com/avatar'
                    "
                    class="profile-img img-fluid me-2"
                    alt="poster-image"
                  />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <!-- <li>
                    <router-link class="dropdown-item" to="/profile"
                      >Profile</router-link
                    >
                  </li> -->
                  <li>
                    <router-link class="dropdown-item" to="/newpassword"
                      >new password</router-link
                    >
                  </li>

                  <li>
                    <div
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="logout"
                    >
                      Log Out
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </ul>
        </div>
        <div v-if="!isAuthenticated">
          <router-link
            to="/login"
            class="btn btn-primary rounded-pill px-5 mx-2"
            >Login</router-link
          >
          <router-link to="/signup" class="btn btn-dark rounded-pill px-4 mx-2"
            >Sign Up</router-link
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import { isAuth } from "@/utils/misc"

export default {
  name: "TheNavbar",
  methods: {
    ...mapActions("auth", ["logout"])
  },
  computed: {
    ...mapState("user", ["user"]),
    isAuthenticated() {
      return isAuth()
    }
  }
}
</script>

<style scoped>
.search-bar {
  background-color: #eeeeee;
  border-radius: 50px;
  width: 22rem;
}
.search-box {
  background-color: #eeeeee;
  border: none;
  width: 20rem;
  outline: none;
}
.nav-link-icons {
  background: #eaedf2;
  border-radius: 100px;
  color: #000;
}
/* .nav-link-icons:hover{
    background-color: #D2E3FF;
} */
.nav-link-icons:focus {
  background-color: #d2e3ff;
  color: #ff411f;
}

.active-link {
  background-color: #d2e3ff;
  color: #ff411f;
}
.profile-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100px;
}
/* .logo {
  width: 3.8rem;
} */
.navbar-brand {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}
</style>
