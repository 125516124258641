<template>
  <div class="d-flex px-2 justify-content-between align-items-center">
    <div class="ms-4">Active {{ page.current_page }}/{{ page.last_page }}</div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="mx-4">
        <span>Rows per page: </span>
        <select
          class="form-select-sm border-0"
          v-model="perPage"
          @change="onSelectPerPage($event)"
          aria-label="Select Rows per page"
        >
          <!-- <option value="6" selected>6</option> -->
          <option v-for="row in rowsPerPage" :value="row" :key="row">
            {{ row }}
          </option>
        </select>
      </div>
      <div class="mx-4">{{ page.from }}-{{ page.to }} of {{ page.total }}</div>

      <nav aria-label="user-pagination" class="mx-4 mt-3">
        <ul class="pagination">
          <li
            class="page-item"
            v-if="page.current_page > 1"
            @click="loadPrevPage"
          >
            <button class="page-link" aria-label="Previous">
              <span aria-hidden="true">&lt;</span>
            </button>
          </li>

          <li
            class="page-item"
            v-if="page.current_page != page.last_page"
            @click="loadNextPage"
          >
            <button class="page-link" aria-label="Next">
              <span aria-hidden="true">&gt;</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: Object,
    onSelectPerPage: Function,
    loadPrevPage: Function,
    loadNextPage: Function
  },
  data() {
    return {
      perPage: this.page.per_page,
      rowsPerPage: [6, 10, 15, 20]
    }
  },
  watch: {
    page(data) {
      this.perPage = data.per_page
    }
  }
}
</script>
