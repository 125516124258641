<template>
  <div class="card" v-if="stats">
    <div class="card-body">
      <h5 class="card-title">{{ title }} Statistics</h5>
      <div class="container">
        <div class="row">
          <div class="col">
            <h6 class="card-subtitle mb-2 text-muted">Total</h6>
            <p class="card-text">{{ stats.total }}</p>
          </div>
          <div class="col">
            <h6 class="card-subtitle mb-2 text-muted">Today</h6>
            <p class="card-text">{{ stats.today }}</p>
          </div>
          <div class="col">
            <h6 class="card-subtitle mb-2 text-muted">This week</h6>
            <p class="card-text">{{ stats.this_week }}</p>
          </div>
          <div class="col">
            <h6 class="card-subtitle mb-2 text-muted">This month</h6>
            <p class="card-text">{{ stats.this_month }}</p>
          </div>
          <div class="col">
            <h6 class="card-subtitle mb-2 text-muted">This year</h6>
            <p class="card-text">{{ stats.this_year }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminStatistics",
  props: {
    title: String,
    stats: Object
  }
}
</script>
