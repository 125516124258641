<template>
  <dashboard-layout>
    <div class="my-5 py-5 overflow-hidden">
      <div class="row justify-content-center">
        <div class="col-10">
          <admin-statistics title="Post" :stats="stats"></admin-statistics>
          <div class="py-5">
            <div class="pb-3">
              <div class="row">
                <div class="col d-flex">
                  <div class="dropdown">
                    <button class="btn button" type="button" data-bs-toggle="dropdown" >
                      <i class="bi bi-filter fs-3 me-3 text-primary"></i>
                    </button>
                  </div>
                  <div class="d-flex align-items-center search-bar px-2 ">
                    <i class="bi bi-search fs-4"></i>
                    <input
                      type="text"
                      class="search-box py-2 px-2"
                      placeholder="Search.."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white">
              <post-table
                :admin_posts="admin_posts"
              ></post-table>
              <pagination
                :page="page"
                :onSelectPerPage="onSelectPerPage"
                :load-prev-page="loadPrevPage"
                :load-next-page="loadNextPage"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import { mapState, mapActions } from "vuex"
import DashboardLayout from "@/layouts/Dashboard.vue"
import Pagination from "@/components/dashboard/Pagination.vue"
import AdminStatistics from "@/components/dashboard/AdminStatistics.vue"
import { loadScript } from "@/utils/misc"
import PostTable from "@/components/dashboard/PostTable.vue";

export default {
  name: "DashboardPost",
  components: {
    PostTable,
    DashboardLayout,
    Pagination,
    AdminStatistics
  },
  data() {
    return {
      stats: null
    }
  },
  async mounted() {
    loadScript()
    await this.$store.dispatch("user/getCurrentUser")
    this.getPostStats()
    this.getPosts({ per_page: this.page.per_page, page: 1, section: "admin" })
  },
  computed: {
    ...mapState("post", ["admin_posts", "page", "post_stats"]),
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapActions("post", [
      "getPosts",
      "createPost",
      "deletePost",
      "getPostStats"
    ]),
    loadNextPage() {
      this.getPosts({
        per_page: this.page.per_page,
        page: this.page.current_page + 1,
        section: "admin"
      })
    },
    loadPrevPage() {
      this.getPosts({
        per_page: this.page.per_page,
        page: this.page.current_page - 1,
        section: "admin"
      })
    },
    onSelectPerPage(event) {
      const perPage = event.target.value
      this.getPosts({
        per_page: perPage,
        page: this.page.current_page,
        section: "admin"
      })
    }
  },
  watch: {
    post_stats(val) {
      this.stats = {
        total: val.posts_count,
        today: val.posts_today,
        this_week: val.posts_this_week,
        this_month: val.posts_this_month,
        this_year: val.posts_this_year
      }
    }
  }
}
</script>

<style scoped>
.dropdown-menu {
  min-width:200px;
}
.button {
  cursor: pointer;
  border: none;
}
.search-bar {
  background: #dae1ec;
  border-radius: 5px;
  width: 23rem;
}
.search-box {
  background: #dae1ec;
  border: none;
  width: 28rem;
  outline: none;
}
.modal-content {
  width: 41rem;
}
</style>
